import React, { useState } from 'react';
import { CookiesModal } from './CookiesModal';
import { Button } from '@ao-internal/react-components';

function ManageCookiesButton() {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setModalOpen(true)}
        className="u-ta--center c-btn--mpd-grey"
      >
        Manage Cookie Preferences
      </Button>
      <CookiesModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </>
  );
}
export { ManageCookiesButton };
