/**
 * Toggle button switch
 *
 * Usage:
 *
  const [fiveGOn, setFiveGOn] = useState(false);
  return (
    <ToggleSwitch
      active={fiveGOn}
      onClick={() => setFiveGOn(true)}
      label="Show 5G Handsets"
    />
  )
 */
import React from 'react';
import PropTypes from 'prop-types';

import './ToggleSwitch.scss';

interface ToggleSwitchProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active: boolean;
  label: string;
  size: 'xs' | 'sm' | 'lg';
  className?: string;
}

function ToggleSwitch({
  active,
  label,
  size = 'xs',
  ...props
}: ToggleSwitchProps) {
  const activeClassName = active ? 'active' : '';

  return (
    <div>
      <button
        type="button"
        data-toggle="button"
        aria-pressed={active ? 'true' : 'false'}
        aria-label={label}
        {...props}
        className={`c-btn c-btn--toggle c-btn--toggle-${size} u-m--micro ${activeClassName} ${
          props.className || ''
        }`}
      >
        <div className="handle" />
      </button>
      <div className="toggle-switch-label u-px--micro u-fz--caption">
        {label}
      </div>
    </div>
  );
}

ToggleSwitch.propTypes = {
  /**
   * @param active whether the toggle switch is on or off
   */
  active: PropTypes.bool.isRequired,

  /**
   * @param label accessibility label
   */
  label: PropTypes.string.isRequired,

  /**
   * @param size size of button (default "xs").
   *
   * Accepted values: "xs", "sm", "lg"
   */
  size: PropTypes.string,
};

export { ToggleSwitch };
