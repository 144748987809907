let a11yStoredElements = [];

/**
 * Remove all interactive elements from aria dom and
 * prevent tabbing to them to ensure modal is the only
 * interactive area
 */
export function setupFocusTrap() {
  a11yStoredElements = [];
  document
    .querySelectorAll(
      '#root a, #root input, #root button, div[tabIndex], ul[tabIndex]'
    )
    .forEach(element => {
      // Store original element attribute state
      const ariaHidden = element.getAttribute('aria-hidden');
      const tabIndex = element.getAttribute('tabIndex');
      a11yStoredElements.push({
        element,
        ariaHidden,
        tabIndex,
      });
      element.setAttribute('aria-hidden', 'true');
      element.setAttribute('tabIndex', '-1');
    });
}

/**
 * Restore original element attribute states
 */
export function tearDownFocusTrap() {
  a11yStoredElements.forEach(({ element, ariaHidden, tabIndex }) => {
    if (ariaHidden) {
      element.setAttribute('aria-hidden', ariaHidden);
    } else {
      element.removeAttribute('aria-hidden');
    }
    if (tabIndex) {
      element.setAttribute('tabIndex', tabIndex);
    } else {
      element.removeAttribute('tabIndex');
    }
  });
}
