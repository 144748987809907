import './bootstrap';
import './components/SignUpFooter';
require('./components/Cookies/');
document.addEventListener('DOMContentLoaded', function () {
	document
		.querySelectorAll('.header-track-element, [data-track-element]')
		.forEach(function (item) {
			item.addEventListener('click', function (e) {
				window.dataLayer.push({
					event: 'header',
					headerClicked: item.getAttribute('data-track-element'),
					eventCallback: function () {
						window.location.href = item.getAttribute('href');
					},
				});
			});
		});

	globalThis.axios.get('/basket/refresh').then(function (response) {
		const basketExists = !!response.data.id;
		const basket = basketExists ? response.data : null;
		if (basketExists) {
			document
				.querySelectorAll('.item-in-basket')
				.forEach(function (item) {
					item.classList.remove('u-d--none');
				});
		}
		window.MPD = window.MPD || {};
		window.MPD.variantInBasket = response.data.variant;
		window.MPD.tariffInBasket = response.data.tariff;
		// Set up basket with it's data if it's to be rendered on this page
		window.MPD.renderBasket && window.MPD.renderBasket(basket);
	});
});
