import { urlToRelative } from 'components/Common/url-to-relative';
import { submitCookiePreferences } from './Api';

type CookiePreference = 'allowed' | 'blocked';
export type CookiePreferences = {
  analytics: CookiePreference;
  marketing: CookiePreference;
  essential: CookiePreference;
};

const IS_DEV =
  window.location.hostname.indexOf('mobilephonesdirect.co.uk') === -1;

export const BANNER_COOKIE_NAME = 'MPDCookieBannerConsent';
export const PREFERENCES_COOKIE_NAME = 'MPDCookiePreferences';
export function trackCookieBannerInteraction(cookiePreferences) {
  globalThis.dataLayer.push({
    event: 'consentUpdate',
    personalisation: cookiePreferences.analytics,
    remarketing: cookiePreferences.marketing,
    essential: cookiePreferences.essential,
  });
}

export const getCookie = (name: string): string | null => {
  const nameEQ = name + '=';
  const cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    let cookie = cookieArr[i];
    while (cookie.charAt(0) == ' ') cookie = cookie.substring(1, cookie.length);
    if (cookie.indexOf(nameEQ) == 0)
      return cookie.substring(nameEQ.length, cookie.length);
  }
  return null;
};

export const setCookie = (name: string, value: string): void => {
  let expireDate = new Date();
  expireDate = new Date(expireDate.setMonth(expireDate.getMonth() + 6));

  let cookie;

  if (IS_DEV) {
    cookie = `${name}=${value};path=/;expires=${expireDate.toUTCString()};`;
  } else {
    cookie = `${name}=${value};domain=${getCookieDomain()};path=/;expires=${expireDate.toUTCString()};SameSite=None;Secure;`;
  }

  document.cookie = cookie;
};

export const hasUserConsented = (): boolean => {
  const bannerCookieResponse = getCookie(BANNER_COOKIE_NAME);
  return (
    bannerCookieResponse === 'accepted' || bannerCookieResponse === 'declined'
  );
};
export const getCookieDomain = (): string => {
  const host = window.location.host;

  if (!IS_DEV) {
    if (host.includes('mobilephonesdirect.co.uk')) {
      return '.mobilephonesdirect.co.uk';
    }
  }

  return 'mpd-v2.test';
};

export const isCookiesContentPage = (): boolean => {
  const noBannerList = [
    urlToRelative(globalThis.Router('legal', 'cookies').toString()),
  ];
  return noBannerList.some((url: string) => url === window.location.pathname);
};

function setCookieBannerConsent(acceptedOrDeclined: 'accepted' | 'declined') {
  setCookie(BANNER_COOKIE_NAME, acceptedOrDeclined);
}

function setFooterUTR(UTR: string) {
  const cookesFooterElement = document.getElementById('cookies-footer');
  const footerUTRElement = document.getElementById('unique-cookies-reference');
  if (footerUTRElement) {
    footerUTRElement.innerText = UTR;
    cookesFooterElement.classList.remove('u-d--none');
  }
}

export async function setCookiesPreferences(
  cookiePreferences: CookiePreferences,
  bannerConsent: 'accepted' | 'declined' = 'accepted'
): Promise<boolean> {
  const response = await submitCookiePreferences({
    bannerConsent,
    cookiePreferences,
  });
  if (response.status !== 200) {
    return false;
  }
  const UTR = response.data.UTR;

  // Set preferences chosen so user doesn't see banner again
  setCookieBannerConsent(bannerConsent);
  // Save chosen preferences
  setCookie(PREFERENCES_COOKIE_NAME, JSON.stringify(cookiePreferences));
  // Push to data layer
  trackCookieBannerInteraction(cookiePreferences);
  // Set value of UTR in footer
  setFooterUTR(UTR);

  return true;
}
