import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import { CookiesModal } from './CookiesModal';
import {
  hasUserConsented,
  isCookiesContentPage,
  CookiePreferences,
  setCookiesPreferences,
} from './cookies';
import './CookiesBanner.scss';
import { setupFocusTrap, tearDownFocusTrap } from './a11y';

globalThis.MPD = globalThis.MPD || {};
globalThis.MPD.cookieBannerVariation = 'testMiddle';

// control, v1, v2, v3, v4, v5
// Create default settings and extend from them based on variant
const defaultSettings = {
  acceptButtonText: 'Accept',
  acceptColumnClassName:
    'u-grid--6 u-grid--12--bp-medium u-ord--2 u-ord--1--bp-medium',
  acceptButtonClassName: 'u-bgc--action u-c--inverted u-mt--small--bp-medium',
  declineColumnClassName:
    'u-grid--6 u-grid--12--bp-medium u-ord--1 u-ord--2--bp-medium',
  declineButtonClassName: '',
  copyColumnSizeDesktop: 9,
  buttonColumnSizeDesktop: 3,
  acceptButtonStyle: {
    width: '100%',
  },
  declineButtonStyle: {
    width: '100%',
  },
  alternativeManagePreferences: false,
  bannerPlacement: 'bottom',
};
const variantSettings = {
  testMiddle: {
    alternativeManagePreferences: true,
    declineButtonClassName: 'c-btn--blue-outlined u-bd--none u-mt--none',
    bannerPlacement: 'middle stacked-buttons',
    acceptButtonClassName: 'u-bgc--action u-c--inverted u-mt--micro',
    copyColumnSizeDesktop: 12,
    buttonColumnSizeDesktop: 12,
    acceptColumnClassName:
      'u-grid--6 u-grid--12--bp-medium u-ta--center u-pt--small--bp-medium u-ord--2 u-ord--1--bp-medium',
    declineColumnClassName:
      'u-grid--6 u-grid--12--bp-medium u-ta--center u-ord--1 u-ord--2--bp-medium',
  },
};

function CookiesBanner() {
  const [modalOpen, setModalOpen] = useState(false);
  const [showBanner, setShowBanner] = useState(
    !(isCookiesContentPage() || hasUserConsented())
  );
  function onModalAcceptAndClose() {
    setShowBanner(false);
    tearDownFocusTrap();
    globalThis.dataLayer.push({ event: 'acceptedCookies' });
  }

  async function acceptAll() {
    const cookiePreferences: CookiePreferences = {
      analytics: 'allowed',
      marketing: 'allowed',
      essential: 'allowed',
    };
    const success: boolean = await setCookiesPreferences(cookiePreferences);
    if (success) {
      setShowBanner(false);
      tearDownFocusTrap();
    }
  }

  async function decline() {
    const cookiePreferences: CookiePreferences = {
      analytics: 'blocked',
      marketing: 'blocked',
      essential: 'allowed',
    };

    const success: boolean = await setCookiesPreferences(
      cookiePreferences,
      'declined'
    );
    if (success) {
      setShowBanner(false);
      tearDownFocusTrap();
    }
  }

  function managePreferences() {
    globalThis.dataLayer.push({ event: 'managePreferences' });
    setModalOpen(true);
  }

  const currentVariantSettings =
    variantSettings[globalThis.MPD.cookieBannerVariation || ''] || {};

  const settings = {
    ...defaultSettings,
    ...currentVariantSettings,
  };

  const {
    acceptButtonText,
    acceptButtonClassName,
    declineButtonClassName,
    acceptButtonStyle,
    declineColumnClassName,
    acceptColumnClassName,
    copyColumnSizeDesktop,
    buttonColumnSizeDesktop,
    declineButtonStyle,
    alternativeManagePreferences,
    bannerPlacement,
  } = settings;

  useEffect(() => {
    if (showBanner) {
      document.addEventListener('DOMContentLoaded', function () {
        setupFocusTrap();
      });
    }
  }, []);

  return showBanner ? (
    <>
      <div
        id="cookies-banner"
        className={classnames(
          'u-bgc--inverted',
          { 'u-d--none': modalOpen },
          'placement-' + bannerPlacement
        )}
      >
        <div className="o-wrapper u-px--large u-py--medium u-px--small--bp-medium">
          <div className="o-flex-container">
            <div
              className={`o-flex-item u-grid--12 u-grid--${copyColumnSizeDesktop}--bp-medium`}
            >
              <h3 className="u-fz--display--bp-medium u-mb--small">Cookies</h3>

              <p className="u-fz--base-small u-mb--small">
                Cookies are tiny files that live in your browser. Essential ones
                have to be turned on all the time – they help our website work{' '}
                properly. We and our partners use Personalisation, Remarketing
                and Social Media cookies to get to know you, and use this info
                to show off deals we think you’ll love. Analytics cookies give
                us all sorts of nerdy stats that can help make the site better.{' '}
                {alternativeManagePreferences ? (
                  <>
                    Click{' '}
                    <button
                      style={{
                        fontSize: 'inherit',
                        background: 'transparent',
                        cursor: 'pointer',
                      }}
                      className="u-p--none u-m--none u-fw--600 u-c--action"
                      onClick={managePreferences}
                      aria-label="Manage Preferences"
                    >
                      Manage Preferences
                    </button>{' '}
                    to read more.
                  </>
                ) : (
                  <>
                    Click <strong>Manage Preferences</strong> below to read
                    more.
                  </>
                )}
              </p>
              {!alternativeManagePreferences && (
                <button
                  onClick={managePreferences}
                  className="c-btn c-btn--tertiary u-fz--base-small u-ta--left u-fw--500 u-d--none u-d--block--bp-medium u-p--none"
                >
                  Manage Preferences
                </button>
              )}
            </div>
            <div
              className={`o-flex-item u-grid--12 u-grid--${buttonColumnSizeDesktop}--bp-medium`}
            >
              <div className="o-flex-container u-pl--giant--bp-medium">
                <div
                  className={`o-flex-item u-pl--large--bp-medium ${declineColumnClassName}`}
                >
                  <button
                    onClick={() => decline()}
                    className={`c-btn c-btn--tertiary u-c--action u-ta--center ${declineButtonClassName}`}
                    id="cookies-decline-button"
                    style={declineButtonStyle}
                    aria-label="Decline"
                  >
                    Decline
                  </button>
                </div>
                <div
                  className={`o-flex-item u-pl--large--bp-medium ${acceptColumnClassName}`}
                >
                  <button
                    onClick={() => acceptAll()}
                    style={acceptButtonStyle}
                    className={`c-btn ${acceptButtonClassName} u-ta--center`}
                    id="cookies-accept-button"
                    aria-label={acceptButtonText}
                  >
                    {acceptButtonText}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="o-flex-container u-d--block u-d--none--bp-medium">
            <div className="o-flex-item u-grid--12">
              {!alternativeManagePreferences && (
                <button
                  onClick={managePreferences}
                  className="c-btn c-btn--tertiary u-w--100 u-ta--center"
                  aria-label="Manage Preferences"
                >
                  Manage Preferences
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="cookies-overlay"></div>
      <CookiesModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        onAcceptAndClose={onModalAcceptAndClose}
      />
    </>
  ) : null;
}
export { CookiesBanner };
