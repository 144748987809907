import axios from 'axios';
import { updateCSRFToken } from 'components/Common/api';
import { urlToRelative } from 'components/Common/url-to-relative';
import { CookiePreferences, getCookie, setCookie } from './cookies';

export async function submitCookiePreferences(settings: {
  cookiePreferences: CookiePreferences;
  bannerConsent: string;
}) {
  const url = urlToRelative(
    globalThis.Router('api.cookie-preferences.submit').toString()
  );
  await updateCSRFToken();

  return await axios.post(url, { settings });
}

export async function getCookieUTR(): Promise<string> {
  const clientCookieUTR = getCookie('UTR');
  if (clientCookieUTR) {
    return clientCookieUTR;
  }
  const url = urlToRelative(
    globalThis.Router('api.cookie-preferences.refresh').toString()
  );
  const response = await axios.get(url);
  setCookie('UTR', response.data);
  return response.data;
}
