import React, { useState } from 'react';
import { Modal } from 'components/Common/Modal';
import { ToggleSwitch } from 'components/Common/ToggleSwitch';
import { Button } from '@ao-internal/react-components';

import {
  getCookie,
  PREFERENCES_COOKIE_NAME,
  CookiePreferences,
  setCookiesPreferences,
} from './cookies';

import './CookiesModal.scss';
import { urlToRelative } from 'components/Common/url-to-relative';

function CookieRow({ children }) {
  return (
    <div className="o-flex-container u-p--small">
      <div className="o-flex-item u-grid--3 u-grid--2--bp-medium u-ta--center">
        {children[0]}
      </div>
      <div className="o-flex-item u-grid--9 u-grid--10--bp-medium u-fz--base-small">
        {children[1]}
      </div>
    </div>
  );
}

function CookiesModal({
  modalOpen,
  setModalOpen,
  onAcceptAndClose = () => {},
}) {
  const MPDCookiePreferences =
    JSON.parse(getCookie(PREFERENCES_COOKIE_NAME)) || {};
  const [analyticsCookiesOn, setAnalyticsCookiesOn] = useState(
    MPDCookiePreferences.analytics === 'allowed'
  );
  const [marketingCookiesOn, setMarketingCookiesOn] = useState(
    MPDCookiePreferences.marketing === 'allowed'
  );

  async function acceptAndClose(cookiePreferences: CookiePreferences) {
    const success: boolean = await setCookiesPreferences(cookiePreferences);
    if (success) {
      onAcceptAndClose();
      setModalOpen(false);
    }
  }

  return (
    <Modal
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      className="cookies-modal"
      title="Manage Your Cookies"
    >
      <div
        className="o-flex-container u-py--small u-px--medium"
        style={{ background: '#F5F6F7', margin: '-16px' }}
      >
        <div className="o-flex-item u-grid--3 u-grid--2--bp-medium">
          <a href="/" className="u-d--block">
            <img
              src="https://media.mobilephonesdirect.co.uk/images/header/mpd-company-logo-mobile.svg"
              title="Mobile Phones Direct"
              alt="Mobile Phones Direct"
            />
          </a>
        </div>
        <div className="o-flex-item u-grid--9 u-grid--10--bp-medium u-fz--base-small">
          <p>
            Our cookies help to make your shopping experience with us seamless.
            Manage your settings for our different cookies below.
          </p>
        </div>
      </div>

      <CookieRow>
        <ToggleSwitch
          size="sm"
          className="u-ta--center"
          active={analyticsCookiesOn}
          onClick={() => setAnalyticsCookiesOn(!analyticsCookiesOn)}
          label={analyticsCookiesOn ? 'ON' : 'OFF'}
          aria-label={
            'Personalisation Cookies ' + (analyticsCookiesOn ? 'ON' : 'OFF')
          }
        />
        <>
          <h3 className="u-fz--title-small">Personalisation Cookies</h3>
          <a
            href={urlToRelative(
              globalThis.Router('legal', 'cookies').toString()
            )}
          >
            More info
          </a>
          <p>
            Personalisation cookies help us to tailor our website based on your
            interests. They tell us how you’re using the site, so we can show
            you products and offers you might like.
          </p>
        </>
      </CookieRow>

      <CookieRow>
        <ToggleSwitch
          size="sm"
          className="u-ta--center"
          active={marketingCookiesOn}
          onClick={() => setMarketingCookiesOn(!marketingCookiesOn)}
          label={marketingCookiesOn ? 'ON' : 'OFF'}
          aria-label={
            'Remarketing Cookies ' + (marketingCookiesOn ? 'ON' : 'OFF')
          }
        />
        <>
          <h3 className="u-fz--title-small">Remarketing Cookies</h3>
          <a
            href={urlToRelative(
              globalThis.Router('legal', 'cookies').toString()
            )}
          >
            More info
          </a>
          <p>
            Remarketing cookies record what you’ve found most interesting, so we
            can show you relevant adverts when you’re on other websites. This
            information is all anonymous.
          </p>
        </>
      </CookieRow>

      <CookieRow>
        <div className="u-c--disabled" style={{ fontSize: '3em' }}>
          <span className="icon-accept-v2" />
        </div>
        <>
          <h3 className="u-fz--title-small">Essential Cookies</h3>
          <a
            href={urlToRelative(
              globalThis.Router('legal', 'cookies').toString()
            )}
          >
            More info
          </a>
          <p>
            Essential cookies are necessary for our website to work properly.
            Without them you wouldn’t be able to seamlessly move around our
            website and shop with us.
          </p>
        </>
      </CookieRow>

      <div
        className="o-flex-container u-p--medium"
        style={{ background: '#F5F6F7', margin: '-16px' }}
      >
        <Button
          onClick={() =>
            acceptAndClose({
              analytics: analyticsCookiesOn ? 'allowed' : 'blocked',
              marketing: marketingCookiesOn ? 'allowed' : 'blocked',
              essential: 'allowed',
            })
          }
          aria-label="Accept and Close"
          className="u-w--100 u-ta--center c-btn--mpd-grey"
        >
          Accept & Close
        </Button>
      </div>
    </Modal>
  );
}

export { CookiesModal };
