import React from 'react';
import ReactDOM from 'react-dom';
import { getCookieUTR } from './Api';

import { CookiesBanner } from './CookiesBanner';
import { ManageCookiesButton } from './ManageCookiesButton';

globalThis.renderCookies = () => {
  // Render only if element is available
  const manageCookiesButton = document.getElementById(
    'manage-cookie-preferences'
  );
  if (manageCookiesButton) {
    ReactDOM.render(<ManageCookiesButton />, manageCookiesButton);
  }

  ReactDOM.render(<CookiesBanner />, document.getElementById('cookies-root'));
};
globalThis.renderCookies();

document.addEventListener('DOMContentLoaded', async function () {
  const cookesFooterElement = document.getElementById('cookies-footer');
  const cookiesReferenceElement = document.getElementById(
    'unique-cookies-reference'
  );
  if (cookiesReferenceElement.innerText === '') {
    const UTR = await getCookieUTR();
    if (UTR) {
      cookiesReferenceElement.innerText = UTR;
      cookesFooterElement.classList.remove('u-d--none');
    }
  }
});
