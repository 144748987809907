import { getCSRFToken } from 'components/Common/api';
import { urlToRelative } from 'components/Common/url-to-relative';
import { Fetch } from 'helpers/Fetch';
import { HttpMethod } from 'hooks/use-fetch';
import { ApiResponse } from './types';

export async function handleNewsletterSignUp(
	formData: FormData
): Promise<ApiResponse> {
	const url = new URL(
		globalThis.Router('landing.register-your-interest.create').toString()
	);
	const response = await Fetch<ApiResponse>(urlToRelative(url.toString()), {
		method: HttpMethod.POST,
		body: formData,
		throwOnError: false,
		headers: {
			// TODO: This is a hack. Hoping we can fix CSRF tokens down the line
			'X-CSRF-TOKEN': await getCSRFToken(),
		},
	});
	return response.data;
}
